@slider-width: 1000px;
@slider-height: 300px;
@slider-image-height: @slider-height + 1; // we add one so that there is no 1px whitespace to the right of the image

/* Contest Section */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "flux/layout/CategoryCard.less";

.pinky-template {
    .main-content {
        .type(landing);
        background-color: @site-bg-color;
        position: relative;
    }

    [class^="fl-icon-"],
    [class*=" fl-icon-"] {
        font-family: "fl-icon";
    }

    .page-top-navigation {
        width: 100% !important;
    }

    /***************************************/
    /* Header Carousel Items */
    /***************************************/
    .page-carousel {
        height: 650px;
        overflow: hidden;
        position: relative;

        @media (max-width: @breakpoint-desktop-large) {
            height: 400px;
        }
    }

    .carousel {
        margin: 0;
        min-width: 1000px;
        overflow: hidden;
        position: relative;

        @media (max-width: @breakpoint-desktop-large) {
            min-width: 0;
        }
    }

    .carousel .carousel-inner {
        line-height: 0;
        list-style: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;

        .slick-dots {
            bottom: 100px;
            display: block;
            left: auto;
            margin: 0 auto;
            max-width: 1200px;
            position: relative;
            text-align: left;
            width: 1200px;

            li {
                button {
                    &::before {
                        color: @septenary-color-xxxlight;
                        opacity: 0.25;
                    }
                }
            }

            .slick-active {
                button {
                    &::before {
                        color: @septenary-color-xxxlight !important;
                        opacity: 1;
                    }
                }
            }
        }

        @media (max-width: @breakpoint-desktop-large) {
            .slick-dots {
                display: none !important; /* override inline js display styling */
            }
        }
    }

    .is-mobile-first & {
        @media (max-width: @breakpoint-mobile) {
            .page-carousel {
                height: 500px;
            }
        }
    }

    .carousel-item {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-size: 80;
        float: left;
        height: 650px;
        position: relative;
        width: 100%;

        &-description {
            .type(ui);
            bottom: -5px;
            color: @septenary-color-xxxlight;
            display: inline-block;
            left: 50%;
            margin-left: -8%;
            position: absolute;
            text-align: center;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
            width: 50%;
        }

        &-link {
            color: @septenary-color-xxxlight;
        }

        &-heading {
            display: block;
        }

        &--hero-first {
            background-color: #222826;
            background-image: url("/static/css/images/contest-home/teedesign-arkyd.jpg");
        }

        &--hero-second {
            background-color: #a44952;
            background-image: url("/static/css/images/contest-home/bookdesigns.jpg");
        }

        &--hero-third {
            background-color: #ecb769;
            background-image: url("/static/css/images/contest-home/illustration.jpg");
            background-position-x: 67%;
        }

        &--hero-fourth {
            background-color: #532815;
            background-image: url("/static/css/images/contest-home/logo-sailsofglory.jpg");
            background-position-x: 67%;
        }

        &--hero-fifth {
            background-color: #222826;
            background-image: url("/static/css/images/contest-home/zewurstlogo.jpg");
            background-position-x: 65%;
        }

        @media (max-width: @breakpoint-desktop-large) {
            background-image: linear-gradient(to bottom, @primary-color-xxlight, @primary-color);
            height: 400px;

            &-description {
                display: none;
            }
        }

        .is-mobile-first & {
            @media (max-width: @breakpoint-mobile) {
                height: 500px;
            }
        }
    }

    .page-banner-content-wrapper {
        margin: 0 auto;
        max-width: 1200px;
    }

    @media (max-width: 1240px) {
        .page-banner-content-wrapper {
            padding-left: 20px;
        }
    }

    .page-banner-content {
        position: absolute;
        text-align: left;
        top: 190px;
        z-index: 10;

        .page-banner-description {
            .type(heading3);
            color: @septenary-color-xxxlight;
            margin: 0 0 25px;
            max-width: 500px;
            white-space: normal;
            width: 95%;
        }

        .page-banner-cta:not(:first-of-type) {
            display: none;
        }

        .page-banner-subtext {
            .type(ui);
            color: @text-color-light;
            margin-top: 24px;
            max-width: 600px;
        }

        .page-banner-title {
            color: @septenary-color-xxxlight;
            font-size: 48px;
            font-weight: bold;
            letter-spacing: -1px;
            line-height: 1;
            margin-bottom: 20px;
            max-width: 500px;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);
            word-break: break-word;

            &.short-text {
                white-space: normal;
                width: 450px;
            }
        }

        .page-banner-altText {
            .type(heading3);
            font-weight: normal;
        }

        @media (max-width: @breakpoint-desktop-large) {
            .horizontal-vertical-align();
            text-align: center;
            width: 90vw;

            .page-banner-title,
            .page-banner-text,
            .page-banner-subtext {
                margin-left: 0;
                max-width: none;
            }

            .page-banner-description {
                margin-left: auto;
                max-width: none;
                width: 100%;
            }
        }

        .is-mobile-first & {
            @media (max-width: @breakpoint-mobile) {
                .page-banner-title {
                    .type(heading1);
                }

                .page-banner-cta {
                    display: none;

                    &:last-of-type {
                        display: block;
                    }
                }

                .page-banner-subtext {
                    margin-left: 0;
                }
            }
        }
    }

    /***************************************/
    /* Top Navigation */
    /***************************************/

    .page-top-navigation {
        background-color: @septenary-color-xxxlight;
        z-index: 1040;

        li {
            display: inline-block;
            list-style: none;
            padding: 0 30px;

            &.active-page {
                font-weight: bold;
            }

            &:first-child {
                padding-left: 0;
            }
        }

        a {
            .type(landing);
            border-top: 4px solid transparent;
            color: @septenary-color-xdark;
            display: inline-block;
            padding: 30px 0;
            text-decoration: none;

            &:hover {
                border-top: 4px solid @septenary-color-xlight;
            }
        }
    }

    @media (max-width: @breakpoint-tablet) {
        .page-top-navigation-item {
            padding: 0 @spacing-mid;
        }
    }

    @media (max-width: @breakpoint-desktop-large) {
        .sticky-wrapper {
            display: none;
        }

        .page-top-navigation {
            padding: @spacing-mid 0;
        }
    }

    /***************************************/
    /* Create Form */
    /***************************************/

    .page-create-form {
        background: url("/static/css/images/contest-home/aluminum-seamless.png");
        overflow: hidden;
        padding: 50px 0 0;
        text-align: center;

        h2 {
            .type(heading1);
        }

        h3 {
            .type(landing);
            margin-bottom: 10px;
        }

        .page-create-form-sections {
            margin: 50px auto;
            margin-left: 50px;
            overflow: hidden;
            padding: 0;
            width: 80%;

            ul {
                list-style: none;
                margin: 0 auto;
                padding: 0;
                width: 260px;
            }

            > li {
                .make-xs-column(6);
                list-style: none;
                text-align: center;

                p {
                    .type(ui);
                }

                span {
                    display: inline-block;
                    height: 40px;
                    width: 40px;
                }
            }

            form {
                background-color: @septenary-color-xxxlight;
                border-radius: 10px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                overflow: hidden;
                padding: 40px;
                position: relative;

                h3 {
                    .type(heading3);
                    margin-bottom: 25px;
                }

                button {
                    margin-top: 10px;
                    width: 100%;
                    .type(heading3)!important;
                }

                .form-step {
                    padding-bottom: 10px;
                }
            }

            .page-create-contest-link {
                color: @quaternary-color-xlight;
                margin-bottom: 0;
                margin-top: 24px;
                .type(ui);

                a {
                    color: @tertiary-color;
                }
            }

            .page-learn-more {
                background: @quaternary-color-xdark;
                bottom: 0;
                left: 0;
                margin: 0;
                padding: 20px 0;
                position: absolute;
                width: 100%;

                a {
                    .type(ui);
                    color: @quaternary-color-xxxlight;
                    font-weight: bold;
                }

                em {
                    border: 1px solid;
                    border-radius: 20px;
                    display: inline-block;
                    font-weight: normal;
                    height: 18px;
                    line-height: 16px;
                    margin-left: 10px;
                    vertical-align: baseline;
                    width: 18px;
                }
            }

            span {
                background: url("/static/css/images/contest-home/contest-home-sprite.png") no-repeat;
                display: inline-block;
                height: 62px;
                width: 62px;
            }

            .page-create-list-guaranteed {
                background-position: 0 0px;
            }

            .page-create-list-featured {
                background-position: -40px 0px;
            }

            .page-create-list-contest {
                background-position: -80px 0px;
            }
        }

        &-featured-in {
            background: @quaternary-color-xxxlight;
            clear: both;
            margin-bottom: 0;
            overflow: visible;
            padding: 70px 0;

            li {
                display: inline-block;
                overflow: visible;
                padding: 0 25px;
                vertical-align: middle;
            }

            &::before {
                .type(detail);
                color: @septenary-color;
                content: "proudly featured in";
                display: block;
                margin-bottom: 23px;
                text-transform: uppercase;
            }

            .flicon-forbes {
                height: 32px;
                width: 91px;
            }

            .flicon-the-wall-street-journal {
                height: 32px;
                width: 230px;
            }

            .flicon-time {
                height: 32px;
                width: 80px;
            }

            .flicon-the-new-york-times {
                height: 32px;
                width: 150px;
            }

            .fl-icon-logo-timesonline {
                color: @septenary-color;
            }

            svg {
                stroke-width: 0;
            }
        }
    }

    /***************************************/
    /* Contest Category Card */
    /***************************************/
    .CreateContest {
        background-color: @quaternary-color-xxxxxlight;
        overflow: hidden;
        padding: 80px 0 20px;
        text-align: center;

        &-heading {
            .type(heading1);
        }

        &-description {
            .type(heading3);
            margin: 0 auto 60px;
            width: 620px;
        }

        &-items,
        &-features {
            .site-section-inner();
        }

        &-item {
            .make-xs-column(3);
        }

        &-features::before {
            border-top: 2px dashed @border-color-dark;
            bottom: 0;
            content: "";
            display: inline-block;
            margin: @spacing-xlarge 30% 0;
            position: relative;
            width: 25%;
        }

        &-feature {
            .make-xs-column(4);
            padding: @spacing-xxlarge @spacing-xlarge;

            &-heading {
                .type(heading3);
                font-weight: bold;
                margin-top: 30px;
            }

            &-image-amazingEntries {
                padding-top: 0;
            }

            &-image-satisfaction {
                min-height: 175px;
            }

            &-image-moneyBack {
                padding-top: 3px;
            }
        }

        .btn--contestViewMore {
            margin-top: 20px;
        }

        .CategoryCard-image {
            &--logoDesign {
                margin-left: 3px;
            }

            &--websiteDesign {
                margin-bottom: 20px;
            }
        }

        .is-mobile-first & {
            @media (max-width: @breakpoint-tablet) {
                padding: @spacing-xxxlarge @spacing-mid @spacing-mid;

                &-description {
                    width: auto;
                }

                &-item,
                &-feature {
                    width: 100%;
                }

                &-feature {
                    padding: @spacing-xxlarge 0;
                }
            }
        }
    }

    /***************************************/
    /* Features Items */
    /***************************************/
    .page-features {
        background-color: @quaternary-color-xdark;
        color: @septenary-color-xxxlight !important;
        margin: 0;
        overflow: hidden;
        padding: 100px 0;
        text-align: center;

        h2 {
            .type(heading1);
        }

        &-description {
            font-weight: normal;
            margin: 0 auto;
            width: 500px;
        }

        .site-section-inner {
            height: 850px;
            overflow: hidden;
            position: relative;

            &.view-more {
                height: auto;
            }

            .is-mobile-first & {
                @media (max-width: @breakpoint-mobile) {
                    min-height: 1300px;
                }
            }
        }

        .page-features-view-more {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(30, 38, 53, 1) 50%, rgba(30, 38, 53, 1) 80%);
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQxJSIgc3RvcC1jb2xvcj0iIzMxMzQzYiIgc3RvcC1vcGFjaXR5PSIwLjY4Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjYwJSIgc3RvcC1jb2xvcj0iIzMxMzQzYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
            bottom: 0;
            display: block;
            height: 100px;
            left: 0;
            position: absolute;
            width: 100%;

            button {
                bottom: 0;
                left: 50%;
                margin-left: -101px;
                position: absolute;
            }
        }
    }

    .page-features-list {
        margin-top: 45px;
        overflow: hidden;
        padding: 0;

        li {
            .make-xs-column(2);
            list-style: none;
            padding: 0;
            text-align: center;

            ul {
                padding: 0;
                width: 100%;

                > li {
                    display: inline-block;
                    float: none;
                    margin: 0 auto 8px;
                    width: 90%;
                }
            }
        }

        h3 {
            .type(heading3);
            font-weight: bold;
            margin-top: 10px;
        }

        p {
            .type(detail);
            font-weight: normal;
            line-height: 1.6em;
        }

        img {
            width: 100%;
        }

        .is-mobile-first & {
            @media (max-width: @breakpoint-mobile) {
                .page-features-list-items {
                    width: 100%;
                }
            }
        }
    }

    /***************************************/
    /* Open Contests */
    /***************************************/
    .page-open-contests {
        background-color: @quaternary-color-xxxxxlight;
        border-radius: 10px;
        overflow: hidden;
        padding: 90px 0 40px;
        text-align: center;

        h2 {
            font-size: 28px;
        }

        .contest-table {
            .type(ui);
            background-color: @septenary-color-xxxlight;
            border: none;
            margin-bottom: 40px;

            p {
                margin-bottom: 0;
                margin-left: 100px;
                word-break: break-all;
            }

            th {
                background-color: #0084C1;
                border: none;
                border-collapse: collapse;
                color: #FFFFFF;
                padding: 10px 0;
                text-align: left;

                &:first-child {
                    border-radius: 10px 0 0;
                    border-right: 1px solid #0084C1;
                    padding-left: 20px;
                }

                &:last-child {
                    border-left: 1px solid #0084C1;
                    border-radius: 0 10px 0 0;
                }
            }

            td {
                border: none;
                padding: 10px 0;
                text-align: left;

                &.entries {
                    text-align: center;
                }
            }

            tr:hover {
                background-color: #e6eff4 !important;

                .repost-recent {
                    display: inline-block;
                }
            }

            .contest-link {
                color: black;
            }

            .contest-name {
                width: 50%;
            }

            .date-started {
                text-align: center;
                width: 15%;
            }

            .even {
                background-color: #F8F8F8;
            }

            .entries {
                text-align: center;
                width: 8%;
            }

            .featured-tag {
                height: 15px;
                padding: 0 0 0 10px;
                width: 74px;
            }

            .guaranteed {
                background: url(/static/img/sprite-contest.png) no-repeat;
                background-position: -136px -75px;
                display: inline-block;
                height: 15px;
                width: 74px;
            }

            .prize {
                text-align: center;
                width: 9%;
            }

            .time-left {
                text-align: center;
                width: 35%;
            }

            .thumb {
                border: 0;
                display: inline-block;
                height: 53px;
                margin: 5px 15px 0  5px;
                overflow: hidden;
                padding: 0;
                position: relative;
                width: 80px;
            }

            .repost-recent {
                margin: 10px auto 0;
                white-space: nowrap;
            }
        }

        @media (max-width: @breakpoint-desktop-small) {
            display: none;
        }
    }

    /***************************************/
    /* Testimonials */
    /***************************************/
    .page-testimonials {
        background-color: #292929;
        text-align: center;

        &-content {
            color: @septenary-color-xxxlight;
            line-height: 100%;
            margin-top: 80px;
            padding-left: 20px;
            text-align: left;
            width: 540px;

            h2 {
                .type(heading2);
                font-style: oblique;
                line-height: normal;
                margin-bottom: 10px;
                position: relative;
            }

            .page-testimonials-profile {
                margin-top: 24px;
                text-align: left;

                .is-mobile-first & {
                    @media (max-width: @breakpoint-mobile) {
                        align-items: center;
                        display: inline-flex;
                    }
                }
            }

            .page-testimonials-photo {
                border: 2px solid @septenary-color-xxxlight;
                border-radius: 25px;
                display: inline-block;
                float: left;
                height: 50px;
                margin-right: 10px;
                width: 50px;
            }

            p {
                margin: 0;
                .type(ui);
            }

            strong {
                display: block;
            }

            .is-mobile-first & {
                @media (max-width: @breakpoint-mobile) {
                    margin-top: @spacing-xlarge;
                    padding: 0;
                    text-align: center;
                    width: 84vw;
                }
            }
        }

        .slide {
            background: url(/static/css/images/contest-home/feature-bkg-sprite.jpg) no-repeat;

            .page-testimonials-photo {
                background: url(/static/css/images/contest-home/contest-home-sprite.png) no-repeat;
            }

            &.testimonial-one {
                background-position: 330px 0;

                .page-testimonials-photo {
                    background-position: 0 -200px;
                }
            }

            &.testimonial-two {
                background-position: 280px -300px;

                .page-testimonials-photo {
                    background-position: -50px -200px;
                }
            }

            &.testimonial-three {
                background-position: 390px -600px;

                .page-testimonials-photo {
                    background-position: -100px -200px;
                }
            }

            &.testimonial-four {
                background-position: 390px -900px;

                .page-testimonials-photo {
                    background-position: -150px -200px;
                }
            }
        }
    }

    .slider {
        height: @slider-height;
        margin: 0 auto;
        position: relative;
        width: 100%;

        .slider-viewport {
            height: @slider-height;
            margin: 0 auto;
            overflow: hidden;
            width: 1000px;

            @media (max-width: 1000px) {
                width: 90%;
            }

            @media (max-width: @breakpoint-tablet) {
                width: 100%;
            }
        }

        .slider-slides {
            height: 100%;
            position: relative;
            transition: all 1s ease-in-out;
            width: @slider-width * 100;

            .slide {
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                float: left;
                height: @slider-height;
                line-height: 337px;
                overflow: hidden;
                position: relative;
                text-align: center;
                width: @slider-width;

                @media (max-width: @breakpoint-tablet) {
                    background-position-x: 190px;
                }

                .is-mobile-first & {
                    @media (max-width: @breakpoint-mobile) {
                        background: none;
                        border-left: 0;
                        height: (@slider-height + 20);
                        padding: 0;
                    }
                }
            }

            a {
                height: 300px;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        [class*="fl-icon-chevron-"] {
            .type(heading1);
            border: 2px solid @septenary-color-xxxlight;
            border-radius: 25px;
            cursor: pointer;
            display: inline-block;
            height: 50px;
            line-height: 50px;
            opacity: 0.5;
            position: absolute;
            top: 0;
            width: 50px;
            z-index: 3;

            &.fl-icon-chevron-left {
                background: url(/static/css/images/contest-home/contest-home-sprite.png) 0 -252px no-repeat !important;
                left: 0;
            }

            &.fl-icon-chevron-right {
                background: url(/static/css/images/contest-home/contest-home-sprite.png) -50px -252px no-repeat !important;
                right: 0;
            }
        }

        .slider-prev,
        .slider-next {
            .type(display);
            bottom: 0;
            color: @septenary-color-xxxlight;
            cursor: pointer;
            line-height: 338px;
            position: absolute;
            text-align: center;
            text-decoration: none;
            top: 0;
            width: 50px;
            z-index: 1;

            [class^="fl-icon"] {
                opacity: 1;
                position: static;

                &::before {
                    content: "";
                }
            }
        }

        .slider-prev {
            left: 0;
        }

        .slider-next {
            right: 0;
        }

        .slider-prev,
        .slider-next {
            opacity: 0.5;

            &::hover {
                opacity: 1;
                transition: opacity 0.12s ease-in-out;
            }

            @media (max-width: @breakpoint-desktop-large) {
                display: none !important; //override inline javascript styling
            }
        }

        .is-mobile-first & {
            @media (max-width: @breakpoint-mobile) {
                height: (@slider-height + 20);

                .slider-viewport {
                    height: (@slider-height + 20);
                }
            }
        }
    }

    /***************************************/
    /* Footer */
    /***************************************/
    .page-footer {
        .clearfix();
        background: @primary-color;

        .site-stat {
            .make-xs-column(4);
            color: @septenary-color-xxxlight;
            list-style: none;
            margin: 80px 0;
            text-align: center;
        }

        .site-stats-value {
            .type(heading1);
            display: block;
            font-weight: bold;
        }

        .site-stats-key {
            .type(landing);
            display: block;
            margin-top: 10px;
        }
    }

    .fl-footer-site-statistics {
        padding: 0;
        visibility: hidden;
    }
}
