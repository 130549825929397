/**
 * Category Card
 *
 * Category Card are used to show contest category in card format.
 * LESS Module: CategoryCard.less

 * .CategoryCard--senaryLight - set the gradient background to @senary-color-xlight
 * .CategoryCard--senaryXLight - set the gradient background to @senary-color-xxlight
 * .CategoryCard--primaryLight - set the gradient background to @primary-color-xxlight
 * .CategoryCard--quinaryLight - set the gradient background to @quinary-color-light
 * .CategoryCard--nonaryLight - set the gradient background to @nonary-color-light
 * .CategoryCard--secondaryLight - set the gradient background to @secondary-color-light
 * .CategoryCard--secondaryDark - set the gradient background to @secondary-color-dark
 * .CategoryCard--tertiaryLight - set the gradient background to @tertiary-color-xlight
 *
 * Markup: category-card.html
 *
 * Styleguide: Cards - Category Card
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";

.CategoryCard {
    @this: CategoryCard;
    .card();
    display: flex;
    position: relative;
    flex-direction: column;
    height: 50vh;
    min-height: 260px;
    max-height: 340px;
    overflow: hidden;
    margin-bottom: 25px;
    text-align: center;
    background-image: linear-gradient(to bottom, @quaternary-color-xxxlight, @quaternary-color-light);

    &:hover {
        text-decoration: none;
    }

    &-heading {
        .type(heading3);
        margin: 0;
        padding: 50px 0;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        color: @text-color-light;

        .is-mobile-first & {
            @media (max-width: @breakpoint-tablet) {
                .type(heading1);
            }

            @media (max-width: @breakpoint-mobile) {
                .type(heading3);
            }
        }
    }

    &-image {
        width: 100%;
        margin-top: auto;
    }

    &--senaryLight {
        background-image: linear-gradient(to bottom, @senary-color-xlight, @senary-color-dark);
    }

    &--primaryLight {
        background-image: linear-gradient(to bottom, @primary-color-xxlight, @primary-color);
    }

    &--quinaryLight {
        background-image: linear-gradient(to bottom, @quinary-color-light, @quinary-color-dark);
    }

    &--senaryXLight {
        background-image: linear-gradient(to bottom, @senary-color-xxlight, @senary-color);
    }

    &--nonaryLight {
        background-image: linear-gradient(to bottom, @nonary-color-light, @nonary-color-dark);
    }

    &--secondaryLight {
        background-image: linear-gradient(to bottom, @secondary-color-light, @secondary-color-xdark);
    }

    &--tertiaryLight {
        background-image: linear-gradient(to bottom, @tertiary-color-xlight, @tertiary-color-dark);
    }

    &--secondaryDark {
        background-image: linear-gradient(to bottom, @secondary-color-dark, @secondary-color-xxdark);
    }

    @media (max-width: @breakpoint-tablet) {
        height: auto;
        max-height: none;
    }
}
